import axios from "axios";

export async function get(url: string, data: any) {
  const rs = await axios({
    method: "get",
    url: url,
    data: data,
  });
  return rs?.data;
}

export async function post(url: string, data: any) {
  const rs = await axios({
    method: "post",
    url: url,
    data: data,
  });
  return rs?.data;
}
