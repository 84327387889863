import DashboardPage from "./pages/dashboard";
import "./App.css";
import { AppProvider } from "./context/app-context";

function App() {
  return (
    <AppProvider>
      <DashboardPage></DashboardPage>
    </AppProvider>
  );
}

export default App;
